<template>
  <div>
    <floating-menu :event-id="eventId"></floating-menu>
    <b-card v-if="isLoading && !fetchComplete" class="text-center">
        <b-spinner>Tunggu...</b-spinner>
    </b-card>
    <b-card v-if="!isLoading && fetchComplete">
      <event-field-content :event-id="eventId" :version="version" :isEdit="isEdit" class="mt-2 pt-75"/>
    </b-card>

  </div>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BSpinner
} from 'bootstrap-vue'
import router from '@/router'
import EventFieldContent from './EventFieldContent.vue'
import FloatingMenu from './FloatingMenu.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BSpinner,
    FloatingMenu,
    EventFieldContent,
  },
  data() {
    return {
      eventId: router.currentRoute.params.id,
      isEdit: false,
      isLoading: false,
      fetchComplete: false,
      version: 1
    }
  },
  methods: {
    fetchData() {
      this.isLoading = true

      this.$http.get('/admin/v1/events/' + this.eventId + '/fields')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.version = currentData.registration_system_version
            if(currentData.fields.length > 0) {
                this.isEdit = true
              }
          }

          // console.log(this.isEdit)

          this.fetchComplete = true
          this.isLoading = false
       })
    }
  },
  created() {
    this.fetchData()

  }
}
</script>
